import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import DATA from '../../static/version.json';
import '../styles/index.css';

function Index() {
  const { version } = DATA;
  const path = `https://little-martian-builds.s3.eu-west-2.amazonaws.com/${version}/little-martian-${version}`;

  return (
    <>
      <Helmet>
        <title>Little Martian Demo</title>
      </Helmet>
      <Header />
      <main>
        <div className="DemoHeader">
          <div className="DemoLinks">
            <a href={`${path}-windows.zip`} className="DemoLink">Download for Windows{' '}<img src="/images/windows.png" width="22" height="16" /></a>
            <a href={`${path}-mac.zip`} className="DemoLink">Download for Mac{' '}<img src="/images/mac.png" width="14" height="18" /></a>
            <a href={`${path}-linux.zip`} className="DemoLink">Download for Linux</a>
          </div>
        </div>
        <div className="DemoInfo">
          <p>Hey, thank you for trying out the Little Martian demo! We hope you enjoy playing, and we're super excited to see what you get up to! Come say hello on <a href="http://discord.gg/fH7agQaHPx" className="Header-discord">Discord</a>!</p>
          <p>If you encounter any bugs, please fill out a <a href="https://forms.gle/X9w14ZZTjehGcvCn6">bug report</a>, it'll really help us improve the game.</p>
          <p>And if you have any ideas or comments, please fill out the <a href="https://forms.gle/7TesVZASTKBhH1cx6">feedback form</a>.</p>
          <p>Thank you, and enjoy!</p>
        </div>
      </main>
    </>
  );
}

export default Index;
